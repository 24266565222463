import React, {useEffect, useRef, useState} from 'react'
import './Authorization.scss'
import '../Loader.scss'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import img_checked from '../../assets/checked.png'
import wa_icon from '../../assets/icons/Whatsapp-icon.svg'
import { useParams } from 'react-router-dom';

function Authorization() {
    const [userPhoneNumber, setPhone] = useState('');
    const [userCode, setCode] = useState('');
    const [status, setStatus] = useState('');
    const [userProgram, setProgram] = useState('');

    const phoneInput = useRef(null);
    const codeInput = useRef(null);

    const [userName, setUserName] = useState('');
    const [userBirthDate, setUserBirthDate] = useState('');
    const [userGender, setUserGender] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const [isAwaitingResponse, setAwaitingResponse] = useState(false)

    const api = process.env.REACT_APP_API_URL;

    const product = "ss";

    useEffect(() => {
        setProgram(product && product !== "yp" ? product : "");
    }, [product])

    const sendCode = async (event) => {
        event.preventDefault();
        setAwaitingResponse(true);
        
        if(userPhoneNumber === undefined || !isValidPhoneNumber(userPhoneNumber)){
            toast.error("Invalid phone number. Please make sure you've entered your data correctly and try again.");
            return;
        }

        event.target.disabled = true;
        phoneInput.current.disabled = true;
        
        const data = {
            user_phone: formatPhoneNumber(userPhoneNumber),
            program: userProgram
        }

        axios.post(`${api}/user/verification/request`, data).then((response) => {
            const resStatus = response.data.status;
            setStatus(resStatus);
            if(resStatus === 'awaiting'){
                toast.success("Verification code is on it's way! Open your WhatsApp app.");
            }
            else if (resStatus === 'verified'){
                toast.success("This phone number was already verified!")
            }
            event.target.disabled = false;
        }).catch((error) => {
            toast.error(error.response.data);
        }).finally(() => {
            setAwaitingResponse(false);
        });
    }

    const confirmCode = async (e) => {
        e.target.disabled = true;
        codeInput.current.disabled = true;
        setAwaitingResponse(true);

        const data = {
            user_phone: formatPhoneNumber(userPhoneNumber),
            code: userCode,
            program: userProgram
        }

        axios.post(`${api}/user/verification/confirm`, data).then(async (response) => {
            const resStatus = response.data.status;
            setStatus(resStatus);
            if(response.data.message) {
                toast.error(response.data.message)
            }
            if(resStatus === "invalid_code") return;
            toast.success("Verified successfully!");

            await saveUserInfo(e);
        }).catch((error) => {
            toast.error(error.response.data);
        }).finally(() => {
            setAwaitingResponse(false);
            e.target.disabled = false;
            codeInput.current.disabled = false;
        });
    }

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    }

    const formatPhoneNumber = (phoneNumber) => {
        let result = phoneNumber.replace("+","");
        return result;
    }

    const saveUserInfo = async (e) => {
        e.preventDefault();
        e.target.disabled = true;
        setAwaitingResponse(true);

        const cleanPhone = userPhoneNumber.replace("+", "");

        const data = {
            user_phone: cleanPhone,
            name: userName,
            birth_date: userBirthDate,
            gender: userGender,
            timezone: getTimezone(),
            email: userEmail,
            program: userProgram
        }

        axios.post(`${api}/user/setup/confirmation`, data).then((response) => {
            toast.success("User information updated successfuly!");
            const resStatus = response.data.status;
            setStatus(resStatus);
        }).catch((error) => {
            toast.error(error.response.data);
        }).finally(() => {
            setAwaitingResponse(false);
            console.log(status)
        });
    }

    const getTimezone = () => {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        timezone = timezone === "Europe/Kiev" ? "Europe/Kyiv" : timezone;

        return timezone;
    }

    const getWALink = (program) => {
        let botPhoneNumber = "";
        switch (program) {
            case "ss":
                botPhoneNumber = process.env.REACT_APP_WA_CHATBOT_NUMBER_SS;
                break;
            case "pp":
                botPhoneNumber = process.env.REACT_APP_WA_CHATBOT_NUMBER_PP;
                break;
            case "wl":
                botPhoneNumber = process.env.REACT_APP_WA_CHATBOT_NUMBER_WL;
                break;
            case "st":
                botPhoneNumber = process.env.REACT_APP_WA_CHATBOT_NUMBER_ST;
                break;
        }

        return "https://wa.me/" + botPhoneNumber;
    }

    return (
        <div className="registration form">
            {
                isAwaitingResponse ? (
                    <div className='loader bg'>
                        <div className='loader spinner'></div>
                    </div>
                ) : <></>
            }
            {status === ""
                ? (
                    <>
                    <header>
                        <h3 className='title'>Register for a free trial!</h3>
                    </header>
                    <main>
                        <form className="inputs" onSubmit={sendCode}>
                            <div className='program input-group'>
                                <select id='user_program' required value={userProgram} onChange={(e) => setProgram(e.target.value)}>
                                        <option value={""} disabled>Choose your program</option>
                                        <option value={"ss"}>Stop Smoking</option>
                                        {/* <option value={"pp"}>Peak Performance</option>
                                        <option value={"wl"}>Weight Loss</option>
                                        <option value={"st"}>Stressful Talks</option> */}
                                </select>
                            </div>
                            <div className='name email input-group multiple gap'>
                                <input type='text' id='user_name' required placeholder='Name' onChange={(e) => setUserName(e.target.value)}/>
                                <input type='email' id='user_email' required placeholder='Email' onChange={(e) => setUserEmail(e.target.value)}/>
                            </div>
                            <div className="phone input-group icon gap">
                                <img alt=""  className='icon' src={wa_icon}/>
                                <PhoneInput
                                    ref={phoneInput}
                                    placeholder="Enter phone number"
                                    value={userPhoneNumber}
                                    onChange={setPhone}
                                    id="userPhoneNumber"/>
                            </div>
                            <button className='btn primary submit'>Submit</button>
                        </form>
                    </main>
                    </>
                )
                : status !== "verified" && status !== "done" ?
                (
                    <>
                    <header>
                        <h3 className='title'>Let's finetune your experience</h3>
                    </header>
                    <main>
                        <form className='inputs'>
                            <div className="confirmation input-group multiple gap">
                                <label htmlFor="userConfirmation">Confirmation Code:</label>
                                <div className='input-group icon gap'>
                                    <img alt=""  className='icon' src={wa_icon}/>
                                    <input type="text" ref={codeInput} name="userConfirmation" id="userConfirmation" placeholder='000000' value={userCode} onChange={handleCodeChange}/>
                                </div>
                            </div>
                            <div className='birth_date input-group multiple gap'>
                                <label htmlFor='user_birth_date'>Your birth date</label>
                                <input type='date' id='user_birth_date' required onChange={(e) => setUserBirthDate(e.target.value)}/>
                            </div>
                            <div className='gender input-group multiple gap'>
                                <label htmlFor='user_gender'>Your gender</label>
                                <select id='user_gender' required defaultValue={""} onChange={(e) => setUserGender(e.target.value)}>
                                    <option value={""} disabled>Choose Your option</option>
                                    <option value={"female"}>Female</option>
                                    <option value={"male"}>Male</option>
                                    <option value={"other"}>Other</option>
                                </select>
                            </div>
                            <button className='btn primary submit' onClick={confirmCode}>Submit</button>
                        </form>
                    </main>
                    </>
                )
                :
                (
                    <>
                    <header>
                        <h3 className='title'>You're all set!</h3>
                    </header>
                    <main>
                        <div className='inputs'>
                            <img alt=""  className='confirmed' src={img_checked}></img>
                            <p>All done! Click the button bellow and say "Hi" to the chatbot to start your journey.</p>
                            <a className='btn primary submit' href={getWALink(userProgram)} target='_blank' rel="noreferrer noopener">Go to WhatsApp</a>
                        </div>
                    </main>
                    </>
                )
            }
        </div>
    )
}

export default Authorization