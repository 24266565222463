import React, { useEffect, useState } from 'react'
import './Navigation.scss'
import logo from '../../assets/rainlab_logo.png'
import close_icon from '../../assets/ph_x-bold.svg'
import hamburger_icon from '../../assets/hamburger.svg'
import Collapse from '../Collapse/Collapse'
import { Link } from 'react-router-dom'

function Navigation() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isServicesOpen, setServicesOpen] = useState(false);
    const [isAtTop, setIsAtTop] = useState(true);
    
    useEffect(() => { 
        window.addEventListener('scroll', handleScroll); 
        return () => { 
            window.removeEventListener('scroll', handleScroll); 
        }; 
    }, []);

    const services = [
        {
            title: "Neurowellness",
            href: "https://neuro-research.ai/neurowellness/en"
        },
        {
            title: "Content Neurofeedback",
            href: "https://neuro-research.ai/neurowellness/en"
        },
        {
            title: "Research",
            href: "https://neuro-research.ai/neurowellness/en"
        },
        {
            title: "Software and Web Development",
            href: "https://neuro-research.ai/neurowellness/en"
        },
        {
            title: "Events",
            href: "https://neuro-research.ai/events/en"
        }
    ];

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    }

    const toggleServices = () => {
        setServicesOpen(!isServicesOpen);
    }

    const handleScroll = () => { 
        const isTop = window.scrollY === 0;
        setIsAtTop(isTop);
    }; 


  return (
    <header id='navigation' className={(isAtTop ? "" : "collapsed")}>
        <div className='rainbow'>
            <div className='content'>
                <nav className='programs'>
                    {/* <Link to={`/products/pp`} className='program' id='peakPerformance'>Peak Performance</Link> */}
                    <Link to={`/products`} className='program' id='stopSmoking'>Stop Smoking</Link>
                    {/* <Link to={`/products/wl`} className='program' id='weightLoss'>Weight Loss</Link>
                    <Link to={`/products/st`} className='program' id='stressfulTalks'>Strressful Talks</Link> */}
                </nav>
            </div>
        </div>
        <div id='menu' className={(isAtTop ? "" : "collapsed")}>
            <button className={"menu_item tab " + (isServicesOpen ? "" : "collapsed")} onClick={toggleServices}>Services</button>
            <Link className='menu_item' to={'/products'}>Products</Link>
            <a className='menu_item' id='logo' href="https://neuro-research.ai/quantum/en"><img alt=""  src={logo}/></a>
            <a className='menu_item' href='https://neuro-research.ai/quantum/en#team'>About Us</a>
            <a className='menu_item' href='https://neuro-research.ai/contact-us/en'>Contacts</a>
            <button onClick={toggleMobileMenu}><img alt=""  src={hamburger_icon}/></button>
        </div>
        <div className={'submenu ' + (isServicesOpen ? "" : "hidden ") + (isAtTop ? "" : "collapsed")}>
            {services.map((item, index) => (
                    <a className='menu_item' href={item.href} key={index}>{item.title}</a>
                ))}
        </div>
        <div id='menuMobile' className={isMobileMenuOpen ? "" : "hidden"} onClick={(e) => e.preventDefault()}>
            <div className='content'>
                <nav>
                    <Collapse head="Services" list={services}/>
                    <Link className='menu_item' to={'/products'}>Products</Link>
                    <a className='menu_item' href='https://neuro-research.ai/quantum/en#team'>About</a>
                    <a className='menu_item' href='https://neuro-research.ai/contact-us/en'>Contacts</a>
                </nav>
                <button onClick={toggleMobileMenu}><img alt=""  src={close_icon}/></button>
            </div>
        </div>
    </header>
  )
}

export default Navigation