import React from 'react'
import './Footer.scss'
import logo from '../../assets/rainlab_logo.png'
import fb_icon from '../../assets/icons/socials/facebook.svg'
import yt_icon from '../../assets/icons/socials/youtube.svg'
import li_icon from '../../assets/icons/socials/linkedin.svg'
import gh_icon from '../../assets/icons/socials/github.svg'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer id="contacts">
        <div className="content">
            <a href="https://neuro-research.ai/quantum/en" className="logo">
                <img alt=""  src={logo}/>
            </a>
            <nav className="item-grid navigation">
                <a href="https://neuro-research.ai/quantum/en#services">Services</a>
                <Link className='menu_item' to={'/products'}>Products</Link>
                <a href="https://neuro-research.ai/quantum/en#team">About Us</a>
                <a href="https://neuro-research.ai/contact-us/en">Contacts</a>
            </nav>
            <div className="item-grid additional">
                <span>+1 438 347 46 06</span>
                <span>info@neuro-research.ai</span>
                <span>+380 97 767 65 56</span>
                <span className="socials">
                    <a href="https://www.facebook.com/neuro.research.ai.quantum"  target="_blank" rel="noreferrer noopener"><img alt=""  src={fb_icon}/></a>
                    <a href="https://www.linkedin.com/company/neuro-research-ai-quantum"  target="_blank" rel="noreferrer noopener"><img alt=""  src={li_icon}/></a>
                    <a href="https://www.youtube.com/@neuro-research-ai-quantum"  target="_blank" rel="noreferrer noopener"><img alt=""  src={yt_icon}/></a>
                    <a href="https://github.com/neuro-research-ai-quantum"  target="_blank" rel="noreferrer noopener"><img alt=""  src={gh_icon}/></a>
                </span>
            </div>
        </div>
        <div className="copywrite">
            <div>© RAINLAB 2023. All Rights Reserved</div>
            <div className="additionalLinks"><a href="https://neuro-research.ai/legal/privacy-policy/en">Privacy Policy</a></div>
        </div>
    </footer>
  )
}

export default Footer